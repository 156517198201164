<script>
    import BaseAnimation from '../BaseAnimation';

    export default {
        name: 'fade-up-animation',

        mixins: [BaseAnimation],

        props: {
            element: {
                type: Function,
                required: true,
            },

            yFrom: {
                type: Number,
                default: 50,
            },
        },

        beforeDestroy() {
            if (this.motionAccepted) {
                this.scrollTrigger.getById(this.uid).kill();
            }
        },

        methods: {
            /**
             * Set the initial state of the animated element.
             */
            setInitialStates() {
                let el = this.element();

                if (el._isVue) {
                    el = el.$el;
                }

                if (Array.isArray(el)) {
                    el.forEach((el) => {
                        el.style.opacity = 0;
                    });

                    return;
                }

                el.style.opacity = 0;
            },

            /**
             * Reveal animation.
             */
            async reveal() {
                let el = this.element();

                if (el._isVue) {
                    el = el.$el;
                }

                this.gsap.set(el, {
                    opacity: 0,
                    y: this.yFrom,
                });

                this.gsap.fromTo(
                    el,
                    {
                        opacity: 0,
                        y: this.yFrom,
                    },
                    {
                        opacity: 1,
                        y: 0,
                        scrollTrigger: {
                            trigger: el,
                            id: this.uid,
                            start: 'top 90%',
                        },
                    },
                );
            },
        },
    };
</script>
